// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// flickity
import Flickity from "react-flickity-component"

// gsap
import gsap from "gsap"

// lodash
import throttle from "lodash/throttle"

// utility
import Events from "../../core/classes/events"

// <Slider />
type Props = {
  /** Root element tag */
  tag: string,
  /**
   * Creates and enables custom scrollbar.
   */
  scrollBar: boolean,
  /**
   * flickity props
   * @see https://github.com/theolampert/react-flickity-component
   */
  flickityProps: {
    /** Flickity element tag */
    elementType?: string,
    /** Disable call reloadCells images are loaded */
    disableImagesLoaded?: boolean,
    /** Run reloadCells and resize on componentDidUpdate */
    reloadOnUpdate?: boolean,
    /**
     * Slider contents are static and not updated at runtime. Useful for
     * smoother server-side rendering however the slider contents cannot be
     * updated dynamically.
     */
    static?: boolean,
    /**
     * Flickity initialization opions
     */
    options?: {
      /** Enables dragging and flicking.  */
      draggable?: boolean,
      /**
       * Enables content to be freely scrolled and flicked without aligning
       * cells to an end position.
       */
      freeScroll?: boolean,
      /**
       * Higher friction makes the slider feel stickier. Lower friction makes
       * the slider feel looser.
       */
      freeScrollFriction?: number,
      /**
       * At the end of cells, wrap-around to the other end for infinite
       * scrolling.
       */
      wrapAround?: boolean,
      /**  Groups cells together in slides. */
      groupCells?: boolean | number | string,
      /** Automatically advances to the next cell. */
      autoPlay?: boolean | number,
      /** Pause autoPlay on hover */
      pauseAutoPlayOnHover?: boolean,
      /**
       * Enables fullscreen view of slider. Adds button to view and exit
       * fullscreen.
       */
      fullscreen?: boolean,
      /** Fades between transitioning slides instead of moving. */
      fade?: boolean,
      /** Changes height of slider to fit height of selected slide. */
      adaptiveHeight?: boolean,
      /**
       * watchCSS option watches the content of :after of the slider element.
       * Flickity is enabled if :after content is 'flickity'.
       */
      watchCSS?: boolean,
      /**
       * Use one Flickity slider as navigation for another.
       * - Clicking the nav slider will select the content slider.
       * - Selecting a cell in the content slider will sync to the nav
       *   slider.
       */
      asNavFor?: React.Node | string | { current: any },
      /**
       * The number of pixels a mouse or touch has to move before dragging
       * begins. Increase dragThreshold to allow for more wiggle room for
       * vertical page scrolling on touch devices.
       */
      dragThreshold?: number,
      /**
       * Attracts the position of the slider to the selected cell. Higher
       * attraction makes the slider move faster. Lower makes it move slower.
       */
      selectedAttraction?: number,
      /**
       * Slows the movement of slider. Higher friction makes the slider feel
       * stickier and less bouncy. Lower friction makes the slider feel looser
       * and more wobbly.
       */
      friction?: number,
      /**
       * Unloaded images have no size, which can throw off cell positions. To
       * fix this, the imagesLoaded option re-positions cells once their images
       * have loaded.
       */
      imagesLoaded?: boolean,
      /** Loads cell images when a cell is selected. */
      lazyLoad?: boolean,
      /**
       * Specify selector for cell elements. cellSelector is useful if you have
       * other elements in your slider elements that are not cells.
       */
      cellSelector?: string,
      /**
       * Zero-based index of the initial selected cell.
       */
      initialIndex?: number,
      /**
       * Enable keyboard navigation.
       */
      accessibility?: boolean,
      /**
       * Sets the height of the slider to the height of the tallest cell.
       */
      setGallerySize?: boolean,
      /**
       * Adjusts sizes and positions when window is resized.
       */
      resize?: boolean,
      /**
       * Align cells within the slider element.
       */
      cellAlign?: "left" | "center" | "right",
      /**
       * Contains cells to slider element to prevent excess scroll at
       * beginning or end. Has no effect if `wrapAround: true`.
       */
      contain?: true,
      /**
       * Sets positioning in percent values, rather than pixel values.
       */
      percentPosition?: boolean,
      /**
       * Enables right-to-left layout.
       */
      rightToLeft?: boolean,
      /**
       * Creates and enables previous & next buttons.
       */
      prevNextButtons?: boolean,
      /**
       * Creates and enables page dots.
       */
      pageDots?: boolean,
    },
  },
  /** Custom class for slider element */
  sliderClassName?: string,
  /** Custom class for cell item element */
  cellClassName?: string,
  /** Custom class for scrollbar element */
  scrollbarClassName?: string,
  /** Custom class for root element */
  className?: string,
  /** content */
  children?: any,
  ...
}

type State = {
  progress: number,
}

/**
 * Draggable slider with scrollbar powered by Flickity.
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .slider        | Root element                                           |
 * | .cell            | Cell element                                           |
 * | .scrollbar       | Scrollbar element                                      |
 * |------------------|--------------------------------------------------------|
 *
 * ## See Also
 * - find styles at /app/client/styles/components/slider.scss
 * - [Flickity API](https://flickity.metafizzy.co/)
 */
class Slider extends React.Component<Props, State> {
  static defaultProps = {
    tag: "div",
    scrollBar: false,
    flickityProps: {
      elementType: "div",
      disableImagesLoaded: false,
      reloadOnUpdate: false,
      static: true,
      options: {
        draggable: true,
        freeScroll: false,
        freeScrollFriction: 0.075,
        wrapAround: true,
        groupCells: false,
        autoPlay: false,
        pauseAutoPlayOnHover: true,
        fullscreen: false,
        fade: false,
        adaptiveHeight: false,
        watchCSS: false,
        asNavFor: "",
        dragThreshold: 3,
        selectedAttraction: 0.025,
        friction: 0.28,
        imagesLoaded: true,
        lazyLoad: false,
        cellSelector: ".cell",
        initialIndex: 0,
        accessibility: true,
        setGallerySize: true,
        resize: true,
        cellAlign: "left",
        contain: false,
        percentPosition: false,
        rightToLeft: false,
        prevNextButtons: false,
        pageDots: false,
      },
    },
  }

  state = {
    progress: 0,
  }

  // trackers
  events = new Events()
  handlers = {}

  // refs
  scrollBarWrapper: ?HTMLElement
  scrollBar: ?HTMLElement
  flkty: Flickity

  // custom methods

  getFlickityProps() {
    let { flickityProps } = this.props

    // construct flickity props with defaults
    if (typeof flickityProps !== "object") {
      flickityProps = {}
    }

    flickityProps.options = Object.assign(
      {},
      Slider.defaultProps.flickityProps.options,
      flickityProps.options
    )

    flickityProps = Object.assign(
      {},
      Slider.defaultProps.flickityProps,
      flickityProps
    )

    return flickityProps
  }

  resize() {
    requestAnimationFrame(() => {
      this.flkty.resize()
      this.flkty.reposition()
    })
  }

  tearDown() {
    this.flkty.deactivate()
  }

  rebuild(selectedIndex = 0) {
    this.flkty.selectedIndex = selectedIndex
    this.flkty.activate()
  }

  /** Handle scroll events  */
  handleScroll(e?: Event, progress?: number) {
    if (this.props.flickityProps.reloadOnUpdate) return

    this.setState({ progress }, this.renderScrollBar)
  }

  /** Handle cell click */
  handleCellClick(e: Event) {
    // Use "asNavFor" as references
    const { asNavFor } = this.getFlickityProps().options

    if (
      typeof asNavFor !== "object" ||
      typeof asNavFor.current === "undefined"
    ) {
      return
    }

    const cellElement = e.currentTarget
    const mainElement = asNavFor.current

    const mainFlkty = mainElement.flkty
    const navFlkty = this.flkty

    // get the cell index
    const index = Array.prototype.indexOf.call(
      cellElement.parentNode.children,
      cellElement
    )

    mainFlkty.select(index)
    navFlkty.select(index)
  }

  /** Render the scroll bar if enabled  */
  renderScrollBar() {
    // get element node
    const node = this.scrollBar
    const { progress } = this.state

    // ensure component is enabled && mounted
    if (!this.flkty || !this.props.scrollBar || !node) return

    // calculate scale and x position of scrollbar
    let { slideableWidth, slidesWidth, size } = this.flkty

    let currPos = progress
    let maxPos = slidesWidth
    let percent = currPos / maxPos

    let trackWidth = size.width
    let barWidth = slidesWidth / slideableWidth

    // animate scrollbar parameters
    gsap.to(node, {
      x: percent * (trackWidth - barWidth * trackWidth),
      scaleX: barWidth,
      duration: 0.5,
      ease: "linear.none",
    })

    // hide scrollbar if unneccessary
    gsap.to(this.scrollBarWrapper, {
      opacity: barWidth > 0 ? 1 : 0,
      duration: 0.5,
      ease: "linear.none",
    })
  }

  // react methods

  constructor(props: Props) {
    super(props)

    // bind functions
    ;(this: any).resize = this.resize.bind(this)
    ;(this: any).handleScroll = this.handleScroll.bind(this)
    ;(this: any).renderScrollBar = this.renderScrollBar.bind(this)
  }

  componentDidMount() {
    const { events, flkty } = this

    // fix incorrect sizing due to gatsby cached page loading
    this.resize()

    // fix incorrect sizing after routeUpdate calls
    events.listen("routeUpdate.gatsby", this.resize)

    // handle custom scroll bar
    this.renderScrollBar()
    flkty.on("scroll", this.handleScroll)
    events.listen("resize", throttle(this.renderScrollBar, 100))

    // update selected cell if asNavFor is a ref
    const { asNavFor } = this.getFlickityProps().options
    if (
      typeof asNavFor === "object" &&
      typeof asNavFor.current !== "undefined"
    ) {
      const mainSlider = asNavFor.current
      mainSlider.flkty.on("select", (index) => flkty.select(index))
    }

    // handle prop event handlers
    const {
      onReady,
      onChange,
      onSelect,
      onSettle,
      onScroll,
      onDragStart,
      onDragMove,
      onDragEnd,
      onPointerDown,
      onPointerMove,
      onPointerUp,
      onStaticClick,
      onLazyLoad,
      onBgLazyLoad,
      onFullscreenChange,
    } = this.props

    const handlers = {
      ready: onReady,
      change: onChange,
      select: onSelect,
      settle: onSettle,
      scroll: onScroll,
      dragStart: onDragStart,
      dragMove: onDragMove,
      dragEnd: onDragEnd,
      pointerDown: onPointerDown,
      pointerMove: onPointerMove,
      pointerUp: onPointerUp,
      staticClick: onStaticClick,
      lazyLoad: onLazyLoad,
      bgLazyLoad: onBgLazyLoad,
      fullscreenChange: onFullscreenChange,
    }

    Object.keys(handlers).forEach((eventName) => {
      const handler = handlers[eventName]

      if (typeof handler === "function") {
        this.flkty.on(eventName, handler)
      }
    })
  }

  componentWillUnmount() {
    const { events, flkty } = this

    events.cleanUp()
    flkty.off("scroll", this.handleScroll)

    // @todo flkty.off EVERY flkty.on
  }

  render() {
    let {
      // element props
      tag: SliderTag,
      scrollBar,
      // flickity props
      flickityProps,
      // classes
      sliderClassName,
      cellClassName,
      scrollbarClassName,
      className,
      // content
      children,
      // events
      onReady,
      onChange,
      onSelect,
      onSettle,
      onScroll,
      onDragStart,
      onDragMove,
      onDragEnd,
      onPointerDown,
      onPointerMove,
      onPointerUp,
      onStaticClick,
      onLazyLoad,
      onBgLazyLoad,
      onFullscreenChange,
      // passthru
      ...sliderProps
    } = this.props

    flickityProps = this.getFlickityProps()

    return (
      <SliderTag
        {...classNames("slider").plus(className).plus(sliderClassName)}
        style={{ width: "100%", height: "100%" }}
        {...sliderProps}
      >
        <Flickity flickityRef={(c) => (this.flkty = c)} {...flickityProps}>
          {React.Children.map(children, (child, i) => (
            <div
              key={i}
              {...classNames("cell")
                .plus(cellClassName)
                .plus(child.props.className)}
              style={{ display: "block", width: "100%", height: "100%" }}
              onClick={this.handleCellClick.bind(this)}
            >
              {child}
            </div>
          ))}
        </Flickity>
        {scrollBar && (
          <div
            {...classNames("scrollbar").plus(scrollbarClassName)}
            ref={(c) => (this.scrollBarWrapper = c)}
          >
            <div ref={(c) => (this.scrollBar = c)}></div>
          </div>
        )}
      </SliderTag>
    )
  }
}

/**
 * Exports
 */
export default Slider
